import Image from "next/image";
import Link from "next/link";
import TwoSellSmall from "../public/imgs/2sell-small.png";
import {
    ClientIcon,
    DevIcon,
    DropDownIcon,
    SelectArrowDown,
    SpinIcon,
    UserIcon,
    WarnIcon,
} from "./icons";
import { useRouter } from "next/router";
import { useAppSelector } from "../lib/client/store/hooks";
import { checkPerm } from "../lib/mixedAll";
import { menuAdmin } from "../lib/client/tools";
import { useState } from "react";
import { apiStore } from "../lib/client/api-collection";
import AuthLogin from "./authLogin";

function SignOutButton() {
    return (
        <button
            className="hover:text-slate-100"
            onClick={() => {
                sessionStorage.clear();
                localStorage.clear();
                location.href = "/";
            }}
        >
            Sign Out
        </button>
    );
}

const NavBar: React.FC = () => {
    const user = useAppSelector((state) => state.user);
    const [openIssue, setOpenIssue] = useState(false);
    const router = useRouter();
    let menu: Record<string, string | JSX.Element> = {
        quotes2: "Quotes2",
        quotes: "Quotes",
        addons: "Add-Ons",
    };

    let menu_manager = {
        "prod-revert": "@ Pkgs Client",
        setup: "⚙ Config.",
        "mod-diff-doc": "❒ Doc mods",
        "req-client-data": "⅀ Quoted client",
    };
    let menuAdminShow: Record<string, string> = {};

    if (checkPerm(user).isAdmin) menuAdminShow = menuAdmin;

    const generateMenu = (menu: Record<string, string | JSX.Element>) => {
        const links = Object.entries(menu).map(([url, label]) => (
            <li key={url}>
                <Link
                    href={`/${url}`}
                    title={url}
                    className={
                        router.pathname === `/${url}`
                            ? "active"
                            : "hover:bg-slate-500"
                    }
                >
                    {label}
                </Link>
            </li>
        ));
        return <>{links}</>;
    };

    return (
        <AuthLogin defaultNode={<div></div>}>
            <div className="navbar bg-neutral text-neutral-content mb-2 z-40">
                <div className="navbar-start">
                    <Link
                        href="/"
                        className="btn btn-ghost normal-case text-xl w-20"
                    >
                        <Image
                            src={TwoSellSmall}
                            alt="logo"
                            width={200}
                            priority={false}
                        />
                    </Link>
                </div>

                <div className="navbar-center lg:flex">
                    <ul className="menu menu-horizontal px-1">
                        {generateMenu(menu)}
                    </ul>
                    {checkPerm(user).isManager && (
                        <div className="dropdown z-30">
                            <label
                                tabIndex={0}
                                id="admin-menu"
                                className="m-1 flex items-center cursor-pointer hover:text-slate-500"
                            >
                                <ClientIcon /> <SelectArrowDown />
                            </label>
                            <ul
                                tabIndex={0}
                                className="dropdown-content menu p-2 bg-neutral border border-white  rounded-box w-52"
                            >
                                {generateMenu(menu_manager)}
                            </ul>
                        </div>
                    )}
                    {checkPerm(user).isAdmin && (
                        <div className="dropdown z-30">
                            <label
                                tabIndex={0}
                                id="admin-menu"
                                className="m-1 flex items-center cursor-pointer hover:text-slate-500"
                            >
                                <DevIcon /> <SelectArrowDown />
                            </label>
                            <ul
                                tabIndex={0}
                                className="dropdown-content menu p-2 bg-neutral border border-white  rounded-box w-52"
                            >
                                {generateMenu(menuAdminShow)}
                            </ul>
                        </div>
                    )}
                </div>

                <div className="navbar-end">
                    <div className="dropdown dropdown-end  z-30">
                        <label
                            tabIndex={0}
                            className="m-1 flex items-center cursor-pointer"
                        >
                            <UserIcon className="h-4 w-4" />
                            <DropDownIcon />
                        </label>
                        <ul
                            tabIndex={0}
                            className="dropdown-content menu p-2 bg-neutral border border-white  rounded-box w-52"
                        >
                            <li tabIndex={0}>
                                <small>{user.username}</small>
                            </li>
                            <li>
                                <a
                                    className="hover:text-slate-100"
                                    onClick={() => {
                                        setOpenIssue(true);
                                    }}
                                >
                                    Add Issue{" "}
                                    <WarnIcon className="h-4 w-4 text-error" />
                                </a>
                            </li>
                            <li>
                                <SignOutButton />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {openIssue && <IssueModal close={() => setOpenIssue(false)} />}
        </AuthLogin>
    );
};

export default NavBar;

const IssueModal: React.FC<{ close: () => void }> = ({ close }) => {
    const [issueTxt, setIssueTxt] = useState("");
    const [loading, setLoading] = useState(false);
    return (
        <>
            <input
                type="checkbox"
                checked={true}
                readOnly
                className="modal-toggle"
            />
            <div className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Add new Issue!</h3>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">
                                Describe the problem encountered
                            </span>
                        </label>
                        <textarea
                            className="textarea textarea-bordered h-24"
                            placeholder="text.."
                            onBlur={(e) => setIssueTxt(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="modal-action">
                        <button
                            className="btn btn-sm"
                            onClick={() => {
                                setIssueTxt("");
                                close();
                            }}
                        >
                            Undo
                        </button>
                        <button
                            className="btn btn-sm"
                            onClick={async () => {
                                setLoading(true);
                                issueTxt.length > 0 &&
                                    (await apiStore.addIssue(issueTxt));
                                setIssueTxt("");
                                setLoading(false);
                                close();
                            }}
                        >
                            Send!
                            {loading && (
                                <SpinIcon className="ml-3 h-4 w-4 text-primary" />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
