import {
    Action,
    combineReducers,
    configureStore,
    ThunkAction,
} from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";

import userSlice from "./authSlice";
import cssEditorSlice from "./cssEditorSlice";
import { migrationSlice, structSlice } from "./migrationSlice";
import { quotationSlice, quotesHistorySlide } from "./quotationSlice";
import { resourcesSlice } from "./resourceSlice";
import { quotedSlice } from "./quotedSlice";

const combinedReducer = combineReducers({
    user: userSlice.reducer,
    migrations: migrationSlice.reducer,
    dbstructs: structSlice.reducer,
    quotations: quotationSlice.reducer,
    resources: resourcesSlice.reducer,
    quoteHistory: quotesHistorySlide.reducer,
    cssEditor: cssEditorSlice.reducer,
    quoted: quotedSlice.reducer,
});

export const makeStore = () =>
    configureStore({
        reducer: combinedReducer,
        devTools: true,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ serializableCheck: false }),
        // middleware: (getDefaultMiddleware) =>
        //     getDefaultMiddleware({
        //         immutableCheck: { warnAfter: 128 },
        //         serializableCheck: { warnAfter: 128 },
        //     }),
    });

type Store = ReturnType<typeof makeStore>;

export type AppDispatch = Store["dispatch"];
export type RootState = ReturnType<Store["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export const wrapper = createWrapper(makeStore, { debug: true });
