import {
    Action,
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import {
    dbStructType,
    migrationStoreType,
    migrationType,
} from "../../../@types/quotes";
import { ApiDB } from "../api-collection";

export const migrationSlice = createSlice({
    name: "migrations",
    initialState: { up: [], down: [] } as migrationType,

    reducers: {
        updateMigration: (state, action: PayloadAction<migrationType>) => {
            return { ...state, ...action.payload };
        },
        addUp: (state, action: PayloadAction<migrationType["up"]>) => {
            return {
                down: state.down,
                up: [...state.up, ...action.payload],
            };
        },
        addDown: (state, action: PayloadAction<migrationType["down"]>) => {
            return {
                up: state.up,
                down: [...state.down, ...action.payload],
            };
        },
    },
});

export const structSlice = createSlice({
    name: "structs",
    initialState: { structs: [], migrationsStored: [] } as {
        structs: dbStructType[];
        migrationsStored: migrationType[];
    },
    reducers: {
        setStructs: (state, action: PayloadAction<dbStructType[]>) => {
            return { ...state, structs: action.payload };
        },
        updateStruct: (state, action: PayloadAction<dbStructType>) => {
            return {
                ...state,
                structs: [
                    ...state.structs.filter(
                        (s) => s.schema !== action.payload.schema
                    ),
                    action.payload,
                ],
            };
        },
        setMigrations(state, action: PayloadAction<migrationStoreType[]>) {
            return {
                ...state,
                migrationsStored: action.payload.map((p) => p.data_json),
            };
        },
        updateMigrations(state, action: PayloadAction<migrationType>) {
            return {
                ...state,
                migrationsStored: [...state.migrationsStored, action.payload],
            };
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchStructs.fulfilled, (state, action) => ({
                ...state,
                structs: action.payload,
            }))
            .addCase(fetchMigrations.fulfilled, (state, action) => {
                return {
                    ...state,
                    migrationsStored: action.payload.map((m) => ({
                        ...m.data_json,
                        dtime: m.datetime,
                    })),
                };
            })
            .addCase(HYDRATE, (_state, action) => {
                console.log("hidrate", action);
            })
            .addMatcher(
                (a: Action) => ["setStructs", "updateStruct"].includes(a.type),
                (state, _action) => {
                    console.log("save ", state);
                }
            );
        //.addCase(fetchStructs.rejected, (state, action) => {});
    },
});

export const fetchStructs = createAsyncThunk(
    "structs/fetch",
    async () => (await ApiDB.getStructs()) || []
);

export const fetchMigrations = createAsyncThunk(
    "migrations/fetch",
    async () => (await ApiDB.getMigrations()) || []
);
