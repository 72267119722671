import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { historiesType } from "../../../@types/quotes";
import { apiProd, apiStore } from "../api-collection";

export interface quotedType {
    clients: { client: string; software: string }[];
    histories: historiesType[];
}

export const quotedSlice = createSlice({
    name: "quoted",
    initialState: {
        clients: [],
        histories: [],
    } as quotedType,
    reducers: {
        setQuotes: (state, action: PayloadAction<quotedType["histories"]>) => {
            return { ...state, histories: action.payload };
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchClients.fulfilled, (state, action) => ({
                ...state,
                clients: action.payload,
            }))

            .addCase(fetchQuoted.fulfilled, (state, action) => ({
                ...state,
                histories: action.payload,
            }));
    },
});

export const fetchClients = createAsyncThunk(
    "quoted/fetchClients",
    async () => (await apiProd.getClients()) || []
);

export const fetchQuoted = createAsyncThunk(
    "quoted/fetchQuoted",
    async () => (await apiStore.getAllHistoryQuotes()) || []
);
