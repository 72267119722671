import {
    Action,
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import {
    consumablesType,
    extraPkgs,
    modType,
    pkgType,
    projectType,
    resourcesType,
} from "../../../@types/quotes";
import { apiStore } from "../api-collection";

const initialResources = {
    pkgs: [],
    projects: [],
    mods: [],
    countries: [],
    valutes: [],
    consumables: [],
    packets: [],
    fixedPackets: [],
    consumableDescr: [],
    "company-sector": { ch: {} },
    "country-prefix": [],
    languages: [],
    status: "pending",
    promotionPk: [],
    extraPkgs: [],
};

export const resourcesSlice = createSlice({
    name: "resources",
    initialState: initialResources as resourcesType,
    reducers: {
        updateResourceList: (
            state,
            action: PayloadAction<{
                res: keyof resourcesType;
                data: pkgType | projectType | modType | consumablesType;
            }>
        ) => {
            const { res, data } = action.payload;
            const s = [
                ...(state[res] as (
                    | pkgType
                    | projectType
                    | modType
                    | consumablesType
                )[]),
            ].filter((p) => `${p.id}` !== `${data.id}`);
            state[res] = [...s, data] as any;
            return state;
        },
        rmResourceList: (
            state,
            action: PayloadAction<{
                res: keyof resourcesType;
                data: pkgType | projectType | modType | consumablesType;
            }>
        ) => {
            const { res, data } = action.payload;
            const s = [
                ...(state[res] as (
                    | pkgType
                    | projectType
                    | modType
                    | consumablesType
                )[]),
            ].filter((p) => p.id !== data.id);
            return { ...state, [res]: s };
        },

        updateExtraPkg: (
            state,
            action: { payload: { res: string; data: extraPkgs } }
        ) => {
            const { res, data } = action.payload;
            return {
                ...state,
                extraPkgs: [
                    ...state.extraPkgs.filter((p) => p.id !== data.id),
                    ...(res === "delete" ? [] : [data]),
                ],
            };
        },
        updateOption: (
            state,
            action: { payload: { key: string; val: any } }
        ) => {
            return { ...state, [action.payload.key]: action.payload.val };
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchProjects.fulfilled, (state, action) => ({
                ...state,
                projects: action.payload,
            }))
            .addCase(fetchPkgs.fulfilled, (state, action) => ({
                ...state,
                pkgs: action.payload.map((pkg) => ({
                    ...pkg,
                    name: pkg.custom?.name,
                    description_new: pkg.custom?.description_new,
                    description2: pkg.custom.description2,
                })),
            }))
            .addCase(fetchMods.fulfilled, (state, action) => ({
                ...state,
                mods: action.payload,
            }))
            .addCase(fetchOptions.fulfilled, (state, action) => ({
                ...state,
                ...action.payload,
            }))

            .addMatcher(
                (action: Action) => action.type.includes("pending"),
                (state, _action) => ({ ...state, status: "pending" })
            )
            .addMatcher(
                (action: Action) => `${action.type}`.includes("fulfilled"),
                (state, _action) => ({ ...state, status: "completed" })
            )
            .addMatcher(
                (action) => `${action.type}`.includes("updateResource"),
                (
                    store,
                    action: {
                        type: string;
                        payload: { data: any; res: string };
                    }
                ) => {
                    const api: any = apiStore.set(`${action.payload.res}`);
                    api && api(action.payload.data);

                    return store;
                }
            )
            .addMatcher(
                (action: Action) => action.type.includes("rmResourceList"),
                (
                    _,
                    action: {
                        type: string;
                        payload: { res: string; data: any };
                    }
                ) => {
                    if (action.payload.res === "mods")
                        apiStore.rmMods(action.payload.data);
                    else {
                        apiStore.rmResource(action.payload);
                    }
                }
            )
            .addMatcher(
                (action: Action) => `${action.type}`.includes("updateExtraPkg"),
                (store, _action) => {
                    console.log(apiStore.setExtraPkg(store.extraPkgs));
                }
            );
    },
});

export const fetchProjects = createAsyncThunk(
    "resources/fetchProjects",
    async () => (await apiStore.getProjects()) || []
);

export const fetchPkgs = createAsyncThunk(
    "resources/fetchPkgs",
    async () => (await apiStore.getPkgs()) || []
);

export const fetchMods = createAsyncThunk(
    "resources/fetchMods",
    async () => (await apiStore.getMods()) || []
);

export const fetchOptions = createAsyncThunk(
    "resources/fetchOther",
    async () => (await apiStore.getOptions()) || []
);
