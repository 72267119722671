import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userType } from "../../../@types/quotes";

const initState = {
    username: "",
    name: "",
    accessToken: "",
    idToken: "",
    role: [],
} as userType;

const userSlice = createSlice({
    name: "user",
    initialState: initState,
    reducers: {
        updateUser: (state, action: PayloadAction<Partial<userType>>) => {
            const { username, name, accessToken, idToken, role } =
                action.payload;
            state.username = username || state.username;
            state.name = name || state.name;
            state.accessToken = accessToken || state.accessToken;
            state.role = role || state.role;
            state.idToken = idToken || state.idToken;
        },
    },
});

export default userSlice;
