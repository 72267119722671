import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cssEditorStore } from "../../../@types/quotes";

const cssEditorSlice = createSlice({
    name: "CSSeditor",
    initialState: {
        page: "",
        url: "",
        files: [],
        configuration: {},
    } as cssEditorStore,
    reducers: {
        updateCssEditor: (
            state,
            action: PayloadAction<Partial<cssEditorStore>>
        ) => {
            return { ...state, ...action.payload };
        },
    },
    extraReducers(builder) {
        builder
            //.addCase(fetchStructs.pending, (state) => {})
            .addCase(checkLocalCss.fulfilled, (_state, action) => {
                return action.payload;
            });
        //.addCase(fetchStructs.rejected, (state, action) => {});
    },
});

export default cssEditorSlice;

export const checkLocalCss = createAsyncThunk("structs/fetch", async () => {
    const strdata = localStorage.getItem("cssEditorStore");

    if (strdata) {
        const data = JSON.parse(strdata);
        if (data && Object.keys(data.configuration).length) {
            return data;
        }
    }
    return {};
});
