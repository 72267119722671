import { ReactNode } from "react";
import {
    quotationType,
    pkgType,
    projectType,
    extraPkgs,
} from "../../@types/quotes";

export const menuAdmin = {
    release: "⚡ Release",
    production: "💽 Defines",
    quoted: "☸ Publish Quotes",
    "css-editor": "🎨 CSS Editor",
    databases: "🗃 DB Manager",
    "sources-collection": "© Sources Collection",
};

export const mapObj = (obj: {} | undefined, cb: (d: any) => ReactNode) => {
    return Object.keys(obj || {}).map(cb);
};

export const currency = (val: number = 0, currency: string) =>
    new Intl.NumberFormat(
        typeof window !== "undefined" && window.navigator.language
            ? window.navigator.language
            : "en-GB",
        {
            style: "currency",
            currency,
        }
    ).format(val);

export const downloadPDF = (pdf: string, filename: string) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = filename + ".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink.remove();
};

export const totalsCalc = (
    quotes: Partial<quotationType>,
    withDiscount = false,
    freq = 1
) => {
    /**
     * increment price
     * @param name
     * @returns
     */
    const getInc = (name: string) =>
        quotes?.increase?.find((d) => d.name === name)?.val || 0;
    const valute = quotes.valute || "CHF";

    const e_pkgs: Record<"setup" | "licence", number>[] = (
        quotes.custom?.extrapkgs || []
    ).map((epkg: extraPkgs) => ({
        setup: epkg.projects[`${quotes.project?.id}`].setup?.[valute] || 0,
        licence: epkg.projects[`${quotes.project?.id}`].licence?.[valute] || 0,
    }));
    const pkgsAddon = (
        quotes?.pkgs?.filter((pk) => ["addon", "out"].includes(pk.mode)) || []
    ).map((pk) => ({
        setup:
            pk.customPrices?.setup[valute] ||
            (pk?.prices?.setup || {})[valute] ||
            0,
        licence:
            pk.customPrices?.licence[valute] ||
            (pk?.prices?.licence || {})[valute] ||
            0,
    }));

    let totals: Record<string, Record<string, number>> = {
        setup: {
            setup:
                ((quotes.project?.prices?.setup || {})?.[valute] || 0) +
                getInc("setup"),
            pkgs:
                pkgsAddon.reduce((prev, pk) => prev + pk.setup, 0) +
                e_pkgs.reduce((p: number, epk) => p + epk?.setup, 0) +
                getInc("pkgs"),

            packets:
                (quotes?.costs?.packets || []).reduce(
                    (p, pp) => p + pp.prices[valute],
                    0
                ) + getInc("packets"),
            consumable: Object.keys(quotes?.costs?.consumables || {}).reduce(
                (prev, k) => {
                    const price = (quotes?.costs?.consumables || []).reduce(
                        (p, cc) => p + (cc.count || 1) * cc.prices[valute],
                        0
                    );
                    return prev + price;
                },
                getInc("consumable")
            ),
            fixedPackets: !quotes?.costs?.fixedPackets
                ? 0
                : Object.keys(quotes.costs.fixedPackets).reduce(
                      (prev: number, k: string) => {
                          const price = (
                              quotes?.costs?.fixedPackets || []
                          ).reduce((p, pp) => p + pp.prices[valute], 0);
                          return prev + price;
                      },
                      getInc("plafond")
                  ),
        },
        licence: {
            licence:
                ((quotes.project?.prices.licenceType[0]?.price || {})?.[
                    valute
                ] || 0) *
                    freq +
                getInc("licence"),
            pkglicence:
                (pkgsAddon.reduce((prev, pk) => prev + pk.licence, 0) +
                    e_pkgs.reduce((p: number, epk) => p + epk.licence, 0)) *
                    freq +
                getInc("pkglicence"),
        },
    };

    if (withDiscount) {
        const getDiscount = (name: string, price: number) => {
            const disc = quotes.discount?.find((d) => d.name === name);
            return disc?.type === "fixed"
                ? disc.val
                : (price * (disc?.val || 0)) / 100;
        };

        totals.setupDis = Object.keys(totals.setup).reduce(
            (prev, name) => ({
                ...prev,
                [name]:
                    totals.setup[name] - getDiscount(name, totals.setup[name]),
            }),
            {}
        );
        totals.licenceDis = Object.keys(totals.licence).reduce(
            (prev, name) => ({
                ...prev,
                [name]:
                    totals.licence[name] -
                    getDiscount(name, totals.licence[name]),
            }),
            {}
        );
        totals.total = {
            licence:
                Object.values(totals.licenceDis).reduce(
                    (prev, k) => prev + k,
                    getInc("total-licence")
                ) -
                getDiscount(
                    "total-licence",
                    Object.values(totals.licenceDis).reduce(
                        (prev, k) => prev + k,
                        getInc("total-licence")
                    )
                ),
            setup:
                Object.values(totals.setupDis).reduce(
                    (prev, k) => prev + k,
                    getInc("total-setup")
                ) -
                getDiscount(
                    "total-setup",
                    Object.values(totals.setupDis).reduce(
                        (prev, k) => prev + k,
                        getInc("total-setup")
                    )
                ),
        };
    }

    return totals;
};

export const exportAddonData = (
    project: projectType,
    pkgs: pkgType[],
    valute: string
) => {
    let rows = [];
    rows.push(`${project.name}[${valute}];;;;`);
    rows.push(
        `AddOn Description;Setup price;Licence price;Custom setup price;Custom licence price`
    );

    project.pkgs
        ?.filter((p) => {
            return p.mode === "addon";
        })
        .forEach((p) => {
            const pkg = pkgs.find((pk) => pk.id === p.pkgId);
            rows.push(
                `${pkg?.description};${pkg?.prices?.setup?.[valute] || "?"};${
                    pkg?.prices?.licence?.[valute] || "?"
                };${p.customPrices?.setup?.[valute] || "?"};${
                    p.customPrices?.licence?.[valute] || "?"
                }`
            );
        });
    return rows.join("\n");
};

export const exportProjectsData = (
    projects: projectType[],
    pkgs: pkgType[],
    valute: string
) => {
    let rows = [];

    const list_licence = [
        ...new Set(
            projects.flatMap((p) => {
                const licenceList =
                    typeof p?.prices?.licenceType === "object"
                        ? Object.values(p?.prices?.licenceType)
                        : p?.prices?.licenceType || [];

                return licenceList
                    .sort((a, b) =>
                        a.type > b.type ? 1 : a.limit > b.limit ? 1 : -1
                    )
                    .map((l) => `${l.type} - ${l.limit};`);
            })
        ),
    ];

    rows.push(
        `Project name;Enabled;AddOn(priced/total);Setup price;${list_licence.join(
            ""
        )}`
    );

    projects.forEach((project) => {
        rows.push(
            [
                project.name,
                project.enable ? "enabled" : "disabled",
                `${
                    project.pkgs?.filter((p) => {
                        const pkg = pkgs.find((pk) => pk.id === p.pkgId);
                        return (
                            p.mode === "addon" &&
                            ((p.customPrices?.setup?.[valute] || 0) +
                                (p.customPrices?.licence?.[valute] || 0) !==
                                0 ||
                                (pkg?.prices?.setup?.[valute] || 0) +
                                    (pkg?.prices?.licence?.[valute] || 0) !==
                                    0)
                        );
                    }).length
                }/${project.pkgs?.filter((p) => p.mode === "addon").length}`,
                `${project.prices.setup?.[valute] || "?"}${valute}`,
                ...list_licence.map(
                    (ll) =>
                        ((project.prices.licenceType || []).find(
                            (lt) => ll === `${lt.type} - ${lt.limit};`
                        )?.price?.[valute] || "?") + valute
                ),
            ].join(";")
        );
    });
    return rows.join("\n");
};

export class copyContent {
    static async checkCopy() {
        const result = await navigator.permissions.query({
            name: "write-on-clipboard" as PermissionName,
        });

        return result.state == "granted" || result.state == "prompt";
    }

    static async setCopy(text: string, cb?: (t: string) => void) {
        try {
            await navigator.clipboard.writeText(text);
            cb && cb("Content copied to clipboard");
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    }
}

export function downloadObjectAsJson(exportObj: any, exportName: string) {
    var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}
