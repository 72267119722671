import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { historiesType, quotationType } from "../../../@types/quotes";
import { apiStore } from "../api-collection";

const initialQuotation = {
    steps: { actual: 0, break: 0 },
    valute: "CHF",
    updateAt: null,
    lastUpdateResource: null,
    pkgs: [],
    project: undefined,
    clientData: [
        { name: "foro", value: "lugano" },
        { name: "durata", value: "biennale" },
    ],
    promotion: {},
    custom: { training: 4, max_limit_pay: 30 },
    costs: {
        preCost: undefined,
        consumables: undefined,
        packets: undefined,
    },
    discount: [],
    increase: [],
};

export const quotationSlice = createSlice({
    name: "quotations",
    initialState: initialQuotation as quotationType,
    reducers: {
        updateQuote: (state, action: PayloadAction<Partial<quotationType>>) => {
            return { ...state, ...action.payload };
        },
        resetQuote: () => {
            return initialQuotation;
        },
        updateStep: (
            state,
            action: PayloadAction<Partial<quotationType["steps"]>>
        ) => {
            return { ...state, steps: action.payload } as quotationType;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchQuote.fulfilled, (state, action) => ({
            ...state,
            ...action.payload?.quote,
        }));
    },
});

export const quotesHistorySlide = createSlice({
    name: "quoteHistory",
    initialState: [] as historiesType[],
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchHistoryQuotes.fulfilled, (state, action) => {
            return action.payload;
        });
    },
});

export const fetchHistoryQuotes = createAsyncThunk<
    historiesType[],
    { user?: string; id?: string }
>(
    "quotations/fetchHistory",
    async ({ user, id }) =>
        (await apiStore.getHistoryQuotes({ user, id })) || []
);

export const fetchQuote = createAsyncThunk<
    | {
          client_name: string;
          project: string;
          quote: quotationType;
      }
    | undefined,
    { id: string }
>("quotations/fetchQuote", async ({ id }) => await apiStore.getQuote(id));
