import Image from "next/image";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
} from "@azure/msal-react";
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { useEffect, useRef, useState } from "react";
import { AzureIcon, LocketIcon, SpinIcon } from "./icons";
import { useRouter } from "next/router";
import { useAppDispatch } from "../lib/client/store/hooks";
import userSlice from "../lib/client/store/authSlice";
import { toast } from "react-toastify";
import twoSellSmall from "../public/imgs/2sell-small.png";
import { TESTING } from "../pages/_app";
import { refreshToken } from "../lib/client/msal";

const AuthLogin: React.FC<{
    children: React.ReactNode;
    roleMin?: string;
    defaultNode?: React.ReactNode;
}> = ({ children, roleMin = "client", defaultNode }) => {
    const { instance, accounts, inProgress } = useMsal();
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const btnLoginRef = useRef<HTMLButtonElement>(null);
    const router = useRouter();
    // console.log("is_testing", TESTING);
    useEffect(() => {
        if (
            !loading &&
            inProgress === InteractionStatus.None &&
            accounts.length > 0
        ) {
            const tokenRequest = {
                account: accounts[0], // This is an example - Select account based on your app's requirements
                scopes: ["User.Read"],
            };
            //console.log({ accounts });
            // Acquire an access token
            instance
                .acquireTokenSilent(tokenRequest)
                .then(({ accessToken, idToken, account }) => {
                    const { idTokenClaims, ...acc } = account || {};
                    dispatch(
                        userSlice.actions.updateUser({
                            accessToken,
                            idToken,
                            role: idTokenClaims?.roles?.map((r) =>
                                r.replace("role_", "")
                            ) || ["client"],
                            ...acc,
                        })
                    );
                    setInterval(refreshToken, 5 * 60 * 1000);
                })
                .catch(async (e) => {
                    // Catch interaction_required errors and call interactive method to resolve
                    if (e instanceof InteractionRequiredAuthError) {
                        headlerLogin();
                    }

                    throw e;
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProgress, accounts, instance, loading]);

    const headlerLogin = () => {
        setLoading(true);
        // trick for firefox (or chrome windows)
        setTimeout(() => {
            try {
                instance
                    .loginPopup({
                        scopes: ["user.read"], // optional Array<string>
                    })
                    .then((r) => {
                        const { accessToken, idToken, account } = r;
                        const { idTokenClaims, ...acc } = account || {};
                        dispatch(
                            userSlice.actions.updateUser({
                                accessToken,
                                idToken,
                                role: idTokenClaims?.roles,
                                ...acc,
                            })
                        );
                        setLoading(false);
                    })
                    .catch((e) => {
                        console.error("Login Azure Error", e);
                        setLoading(false);
                        router.reload();
                        //instance.loginRedirect();

                        //btnLoginRef?.current?.click();
                    });
            } catch (e) {
                console.error(e);
                toast.warn("error login popup");
            }
        }, 300);
    };
    if (TESTING) return <>{children}</>;
    return (
        <>
            <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {defaultNode || (
                    <div className="container mx-auto">
                        <div className="grid grid-cols-1 min-h-screen -mt-20">
                            <div className="card sm:w-full md:w-3/4 lg:w-1/2 bg-neutral text-white shadow-xl place-self-center">
                                <div className="card-body">
                                    <div className="w-1/3 mx-auto">
                                        <Image
                                            priority
                                            src={twoSellSmall}
                                            alt="logo"
                                            className="w-40"
                                        />
                                    </div>
                                    <h4>
                                        <LocketIcon className="float-left mr-2 h-6 w-6" />
                                        Sign in into your account
                                    </h4>
                                    <button
                                        ref={btnLoginRef}
                                        className="btn btn-outline text-white"
                                        onClick={headlerLogin}
                                    >
                                        {loading && <SpinIcon />}Sign In{" "}
                                        <AzureIcon className="fill-blue-500 ml-2 h-6 w-6" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </UnauthenticatedTemplate>
        </>
    );
};

export default AuthLogin;
