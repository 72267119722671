import _ from "lodash";

import {
    columnType,
    dbStructType,
    migrationPartType,
    migrationType,
    userType,
} from "../@types/quotes";
import { TESTING } from "../pages/_app";
import moment from "moment";

export const excludeDef = [
    "DATABASE",
    "DATABASE_SLAVE",
    "DATABASE_ARCA24",
    "DATABASE_LOGIN",
    "DATABASE_ADMIN",
    "DATABASE_REPORTING",
    "S3_API_SECRET",
    "MANTICORE",
    "MANTICORE_ARCA24",
    "SPHINX",
    "SPHINX_ARCA24",
    "ELASTICSEARCH",
    "ELASTICSEARCH_ARCA24",
    "GLOBAL_ASSETS_URL",
    "PUSHER24_WS",
];

export const migrationColType = [
    "INT*",
    "VARCHAR",
    "TINYINT*",
    "SMALLINT*",
    "MEDIUMINT*",
    "BIGINT*",
    "TINYTEXT",
    "TIMESTAMP",
    "DOUBLE*",
    "MEDIUMTEXT",
    "DATE",
    "TEXT",
    "BLOB",
    "VARBINARY",
    "FLOAT*",
    "DECIMAL",
    "MEDIUMBLOB",
    "TINYBLOB",
    "DATETIME",
    "TIME",
    "JSON",
];

export const roles = {
    0: "admin",
    1: "tester",
    2: "develop",
    5: "manager",
    10: "customer",
    11: "sale",
};

export const defaultMigration: migrationPartType = {
    schema: "",
    table: "",
    columns: {},
    note: "",
    data: [],
};

/**
 * Used only in Release page
 * Model db name
 */
export const dbList = [
    "arca24_global",
    "client_ats",
    "client_administration_ch",
    "client_administration_it",
    "client_administration_uk",
    "client_administration_us",
    "client_administration_li",
    "client_administration_cz",
    "partner",
    "jobarch",
    "fs",
    "maintenance",
    "eurointerim",
];

export const checkPerm = (user: userType) => ({
    isAdmin:
        TESTING ||
        user.role?.includes("admin") ||
        user.role?.includes("role_tester") ||
        false,
    isManager:
        user.role?.includes("admin") || user.role?.includes("manager") || false,
});

export const getTypeOfVal = (val: any) => {
    if (typeof val !== "string") return typeof val;
    if (!val || val === "") return "string";
    if (/^true$|^false$/.test(`${val}`)) return "boolean";
    if (/\{.*\}$|\[.*\]$/g.test(`${val}`)) return "object";
    if (`${val}`[0] !== "0" && /^[0-9.]*$/g.test(`${val}`)) return "number";

    return "string";
};

/**
 * Convert migration object struct in SQL language
 * @param structs
 * @param migrations
 * @param migkeys 'up'|'down'
 * @returns
 */
export const generateSQL = (
    structs: dbStructType[],
    migrationsStored: migrationType[],
    migrations: migrationType,
    migkeys: "up" | "down"
) => {
    // utility
    const default_str = (str: string) => {
        return `${
            /NULL/i.test(str)
                ? "NULL"
                : /current|now\(\)/i.test(str)
                ? "now()"
                : `'${str.replace(/\'/g, "")}'`
        }`;
    };

    const migStoreHistory = [
        ...migrationsStored
            .slice()
            .sort((a, b) =>
                !b.dtime ? 0 : !a.dtime ? 1 : a.dtime > b.dtime ? 1 : -1
            )
            .filter((m) => moment(m.dtime).isBefore(migrations.dtime)),
        // .sort((a, b) => (`${a.dtime}` > `${b.dtime}` ? 1 : -1)),
        ...(migkeys === "down" ? [migrations] : []),
    ];
    let structsData = [];

    try {
        // construct models with new migrations
        structsData = [...structs].map((s) => {
            const { schema, tables } = s;
            let structClone = { schema, tables: { ...tables } };
            const migStoreHistory_schema_up = migStoreHistory
                .filter((m) => m.up.some((mm) => mm.schema === s.schema))
                .map((m) => m.up);
            //
            for (const mig of migStoreHistory_schema_up) {
                for (const mm of mig) {
                    if (s.schema !== mm.schema || mm.table === "") continue;
                    if (mm.delete) {
                        delete structClone.tables[mm.table];
                    } else if (
                        Object.values(mm.columns || {}).some((mc) => mc.delete)
                    ) {
                        for (const mc of Object.values(mm.columns || {}))
                            if (
                                mc.delete &&
                                structClone.tables[mm.table]?.columns &&
                                mc.delete in
                                    structClone.tables[mm.table].columns
                            ) {
                                delete structClone.tables[mm.table].columns[
                                    mc.delete
                                ];
                            } else {
                                // console.log(
                                //     `Alert ${mc.delete} not found in ${mm.schema} > ${mm.table}`,
                                //     structClone.tables[mm.table]
                                // );
                            }
                    } else {
                        structClone = {
                            ...structClone,
                            tables: {
                                ...structClone.tables,
                                [mm.table]: {
                                    ...(structClone.tables?.[mm.table] || {}),
                                    columns: {
                                        ...(
                                            structClone.tables?.[mm.table] || {}
                                        ).columns,
                                        ...(mm?.columns || {}),
                                    },
                                    constraint: {
                                        ...(
                                            structClone.tables?.[mm.table] || {}
                                        ).constraint,
                                        ...(mm?.constraint || {}),
                                    },
                                    index: {
                                        ...(
                                            structClone.tables?.[mm.table] || {}
                                        ).index,
                                        ...(mm?.index || {}),
                                    },
                                },
                            },
                        };
                    }
                }
            }
            return structClone;
        });
    } catch (e) {
        console.log(e, migrations);
        throw new Error(`construct models with new migrations: ${e}`);
    }
    try {
        let migs: string[] = [];
        migrations[migkeys].forEach((mig) => {
            migs.push(`-- schema ${migkeys}: ${mig.schema} --`);

            if (!mig.table || mig.table === "") [...migs, "-- Table not set"];
            else if (mig.delete) {
                if (mig.delete === mig.table)
                    migs.push(`DROP TABLE IF EXISTS \`${mig.table}\`;`);
                // generate sql (create generation db)
            } else if (
                mig.flags?.includes("new") &&
                Object.keys(mig?.columns || {}).length > 0
            ) {
                const entriesCols = Object.entries(mig.columns || {});
                const primary = entriesCols
                    .filter(([, c]) => c.flags?.includes("PRIMARY"))
                    .map(([name]) => `\`${name}\``)
                    .join(", ");
                migs.push(
                    `CREATE TABLE IF NOT EXISTS \`${
                        mig.table
                    }\` (\n\t${entriesCols
                        .sort((a, b) =>
                            (a[1]?.order || 0) > (b[1]?.order || 0) ? 1 : -1
                        )
                        .map(
                            ([name, dd]) =>
                                `\`${name}\` ${dd.type} ${
                                    dd.size ? `(${dd.size})` : ""
                                }${
                                    dd.flags?.includes("UNSIGNED")
                                        ? " UNSIGNED"
                                        : ""
                                }${
                                    dd.flags?.includes("NULL")
                                        ? " NULL"
                                        : " NOT NULL"
                                }${
                                    dd.flags?.includes("AUTO_INC")
                                        ? " AUTO_INCREMENT"
                                        : ""
                                }${
                                    dd?.default
                                        ? ` DEFAULT ${default_str(dd.default)}`
                                        : ``
                                }`
                        )
                        .join(",\n\t")} 
                    ${primary !== "" ? `,\nPRIMARY KEY (${primary})` : ""}
                    \n)${
                        mig.custom?.attribute ? ` ${mig.custom?.attribute}` : ""
                    };`
                );
            } else if (mig.note) {
                // commit edit structs (no migration db)
                migs.push(`-- add table note: ${mig.note} --`);
            } else if (mig.columns && Object.keys(mig.columns).length) {
                // generate sql (create generation db)

                const entriesCols = Object.entries(mig.columns || {}).sort(
                    (a, b) => ((a[1].order || 0) > (b[1].order || 0) ? 1 : -1)
                );
                const struct = {
                    ...structsData.find((s) => s.schema === mig.schema)?.tables,
                };

                let cols_sorted_origin = Object.entries(
                    struct?.[mig.table]?.columns || {}
                )
                    .sort(([, a_cdata], [, b_cdata]) =>
                        (a_cdata?.order || 0) > (b_cdata?.order || 0) ? 1 : -1
                    )
                    .map(([cname, cdata], i, objAll) => ({
                        cname,
                        order: cdata.order,
                        pre: !i ? null : objAll[i - 1][0],
                    }));

                let cols_sorted_new = [...cols_sorted_origin].map((c) => ({
                    ...c,
                }));

                entriesCols.map(([col, coldata]) => {
                    const fulltype = `${coldata.type} ${
                        coldata.size ? `(${coldata.size})` : ""
                    } ${
                        coldata.flags?.includes("UNSIGNED") ? "UNSIGNED" : ""
                    } ${
                        coldata.flags?.includes("AUTO_INC")
                            ? " AUTO_INCREMENT"
                            : ""
                    }${
                        coldata.flags?.includes("NULL") ? " NULL" : " NOT NULL"
                    } ${
                        coldata?.default
                            ? ` DEFAULT ${default_str(coldata.default)}`
                            : ``
                    }`;

                    if (coldata.delete && coldata.delete === col) {
                        migs.push(
                            `ALTER TABLE \`${mig.table}\` DROP COLUMN \`${col}\`;`
                        );
                    } else if (
                        struct[mig.table]?.columns.hasOwnProperty(col) &&
                        struct[mig.table].columns[col]
                    ) {
                        let sort = "";
                        // date re-order
                        let idx_col = cols_sorted_new.findIndex(
                            (c) => c.cname === col
                        );
                        let origin_idx_col = cols_sorted_origin.findIndex(
                            (c) => c.cname === col
                        );

                        cols_sorted_new[idx_col].order = coldata.order;

                        cols_sorted_new = cols_sorted_new.sort((a, b) =>
                            (a.order || 0) > (b.order || 0) ? 1 : -1
                        );

                        idx_col = cols_sorted_new.findIndex(
                            (c) => c.cname === col
                        );

                        if (idx_col >= 0) {
                            if (idx_col - 1 < 0)
                                cols_sorted_new[idx_col].pre = null;
                            else if (
                                cols_sorted_new[idx_col].pre !==
                                cols_sorted_new[idx_col - 1].cname
                            ) {
                                cols_sorted_new[idx_col].pre =
                                    cols_sorted_new[idx_col - 1].cname;
                                if (idx_col < cols_sorted_new.length - 1)
                                    cols_sorted_new[idx_col + 1].pre = col;
                            }
                            //     cols_sorted_new.find(
                            //         (c) => c.order === coldata?.order
                            //     )?.pre || null;

                            if (
                                cols_sorted_new[idx_col].pre !==
                                cols_sorted_origin[origin_idx_col]?.pre
                            ) {
                                sort = !cols_sorted_new[idx_col].pre
                                    ? " FIRST"
                                    : ` AFTER \`${cols_sorted_new[idx_col].pre}\``;
                                // update original
                                for (
                                    let i = 0;
                                    i < cols_sorted_new.length;
                                    i++
                                ) {
                                    cols_sorted_new[i].order = i;
                                }
                            }
                        }

                        if (
                            ["type", "size", "flags", "default"].some(
                                (prop) => {
                                    let p = prop as keyof columnType;

                                    return (
                                        sort !== "" ||
                                        JSON.stringify(
                                            struct[mig.table].columns[col][p]
                                        ) !==
                                            JSON.stringify(
                                                mig?.columns?.[col][p]
                                            )
                                    );
                                }
                            )
                        ) {
                            migs.push(
                                `ALTER TABLE \`${mig.table}\` MODIFY \`${col}\` ${fulltype}${sort};`
                            );
                        }
                    } else {
                        let prev_id_col = cols_sorted_new.findIndex(
                            (c) => c.order === coldata.order
                        );

                        let sort =
                            prev_id_col !== -1
                                ? !cols_sorted_origin[prev_id_col]?.pre
                                    ? " FIRST"
                                    : ` AFTER ${cols_sorted_origin[prev_id_col]?.pre}`
                                : "";
                        migs.push(
                            `ALTER TABLE \`${mig.table}\` ADD \`${col}\` ${fulltype}${sort};`
                        );

                        // update new sort
                        if (prev_id_col === -1)
                            cols_sorted_new.push({
                                cname: col,
                                order: coldata.order,
                                pre: cols_sorted_new[cols_sorted_new.length - 1]
                                    ?.pre,
                            });
                        else {
                            cols_sorted_new.splice(prev_id_col, 0, {
                                cname: col,
                                order: coldata.order,
                                pre: cols_sorted_new[prev_id_col]?.pre,
                            });
                            // date re-order
                            for (
                                let i = prev_id_col + 1;
                                i < cols_sorted_new.length;
                                i++
                            ) {
                                cols_sorted_new[i].order = i;
                            }
                        }
                        cols_sorted_new = cols_sorted_new.sort((a, b) =>
                            (a.order || 0) > (b.order || 0) ? 1 : -1
                        );
                    }
                });
                //check primary key
                const alreadyPrimaryCol = Object.entries(
                    struct[mig.table]?.columns || {}
                )
                    .filter(([, c]) => c.flags?.includes("PRIMARY"))
                    .map(([col_name]) => col_name);

                //primary key check
                // if (modify primary col || mig with new key)
                if (
                    entriesCols.some(
                        (col) =>
                            alreadyPrimaryCol.includes(col[0]) ||
                            col[1].flags?.includes("PRIMARY")
                    )
                ) {
                    let new_keys = entriesCols
                        .filter(
                            ([col_name, col]) =>
                                col.flags?.includes("PRIMARY") &&
                                !alreadyPrimaryCol.includes(col_name)
                        )
                        .map(([col_name]) => col_name);
                    let rm_keys = entriesCols
                        .filter(
                            ([col_name, col]) =>
                                alreadyPrimaryCol.includes(col_name) &&
                                !col.flags?.includes("PRIMARY")
                        )
                        .map(([col_name]) => col_name);
                    if (new_keys.length > 0 || rm_keys.length > 0) {
                        const primary_key_list = [
                            ...new_keys,
                            ...alreadyPrimaryCol.filter(
                                (c) => !rm_keys.includes(c)
                            ),
                        ].map((name) => `\`${name}\``);
                        migs.push(
                            `ALTER TABLE \`${mig.table}\` DROP PRIMARY KEY ${
                                primary_key_list.length === 0
                                    ? ""
                                    : `, ADD PRIMARY KEY (${primary_key_list.join(
                                          ", "
                                      )})`
                            };`
                        );
                    }
                }
            }

            if (mig.constraint) {
                // generate sql (create generation db)
                migs.push(
                    Object.entries(mig.constraint)
                        .map(([idx, colList]) =>
                            // `ALTER TABLE ${mig.table} DROP FOREIGN KEY ${idx};\n` +
                            colList === null || !colList?.column
                                ? `ALTER TABLE \`${mig.table}\` DROP FOREIGN KEY ${idx};`
                                : `ALTER TABLE \`${mig.table}\` ADD CONSTRAINT ${idx} ` +
                                  `FOREIGN KEY(${colList.column
                                      .split(",")
                                      .map((c) => `\`${c.trim()}\``)
                                      .join(",")})\n` +
                                  `REFERENCES ${
                                      mig.schema !== colList.schema
                                          ? `{${colList.schema}}`
                                          : ""
                                  } \`${colList.table}\` (${colList.target
                                      .split(",")
                                      .map((c) => `\`${c.trim()}\``)
                                      .join(",")})${
                                      colList.on_d
                                          ? ` ON DELETE ${colList.on_d}`
                                          : ""
                                  }${
                                      colList.on_u
                                          ? ` ON UPDATE ${colList.on_u}`
                                          : ""
                                  };`
                        )
                        .filter(Boolean)
                        .join("\n")
                );
            }

            if (mig.index) {
                migs.push(
                    Object.entries(mig.index)
                        // .filter(([, colList]) => colList?.includes("UNIQUE"))
                        .map(([idx, colList]) =>
                            colList === null
                                ? `ALTER TABLE \`${mig.table}\` DROP INDEX ${idx};\n`
                                : `ALTER TABLE \`${mig.table}\` ADD ${
                                      colList.includes("UNIQUE") ? "UNIQUE" : ""
                                  } INDEX ${idx} (${colList
                                      .filter((c) => c !== "UNIQUE")
                                      .map(
                                          (c) =>
                                              `\`${c.replace(
                                                  /\s(ASC|DESC)/,
                                                  ""
                                              )}\` ${c.replace(
                                                  /\w+\s(ASC|DESC)/,
                                                  "$1"
                                              )}`
                                      )
                                      .join(", ")});\n`
                        )
                        .filter(Boolean)
                        .join("\n")
                );
            }
        });
        // console.log(migs.filter(Boolean));
        const firstLine = migs.shift();
        if (migkeys === "down") migs.reverse();
        return [firstLine, ...migs.filter(Boolean)];
    } catch (e) {
        console.log(e, migrations);
        throw new Error(`Error convert mig in sql: ${e} for ${migrations.up}`);
    }
};

export function difference(object: any, base: any) {
    function changes(object: any, base: any) {
        return _.transform(object, function (result: any, value, key) {
            if (!_.isEqual(value, base[key])) {
                result[key] =
                    _.isObject(value) && _.isObject(base[key])
                        ? changes(value, base[key])
                        : value;
            }
        });
    }
    return changes(object, base);
}
